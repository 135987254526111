import React from 'react';
import { NavLink } from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import {CreatedInDayAgo} from "../../helpers/time"
import 'react-toastify/dist/ReactToastify.css';


import TestService from '../../services/test.service';
//import ConversationService from '../../../../services/conversation.service';
//import TemplateService from '../../../../services/template.service';
import AccountService from '../../services/account.service';
import DomainBlacklistService from '../../services/domainBlacklist.service';

import WordsAnalyzer from '../../components/words-analyzer/words-analyzer.js';
import CheckupDomain from '../../components/checkup-domain/checkup-domain.js';


import './test.scss';

const testTypes = {
	btob: 'Professional',
	btoc: 'Personal',
	all: 'All'
};

const getTypeIco = {
		btob: '👔 Professional inboxes',
		btoc: '🙎 Personal inboxes',
		all: '‍🌎 All types of inboxes'
	}


let adsRd = [2,2,2,2]
adsRd = adsRd[Math.floor(Math.random() * adsRd.length)];


class AppTests extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], spamLogs: [], template: {}, openShowHeadersModal: false, headersToShow: [], blockedNextTest: false, loading: true, showPopup: false, currentPage: 'test', errors: {setup: 0}, currentTest:{account: {}}, currentAccount: {}, test_type: 'all', messageContentPreview: 'content_html'};
		this.reloadTests = this.reloadTests.bind(this);
		this.checkBlockedNextTest = this.checkBlockedNextTest.bind(this);
		this.calculeNumberSetupAlert = this.calculeNumberSetupAlert.bind(this);
		this.loadPopup = this.loadPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.openHeadersModal = this.openHeadersModal.bind(this);
	}

	componentWillMount() {
    this.setState({testId: this.props.match.params.test_id});
    if(this.props.match.params.test_id){
			TestService.get(this.props.match.params.test_id).then((response) => {

				let currentTest = response.body;

				currentTest.results = currentTest.results.sort(function(a, b){
					if(a.provider < b.provider) { return -1; }
					if(a.provider > b.provider) { return 1; }
					return 0;
				});

				this.setState({currentTest: currentTest, loading: false});
				this.filterAccounts(response.body.test_type);

				this.calculeNumberSetupAlert(currentTest);

				setTimeout(() => {
				  this.loadPopup();
				}, 3000);


				if(!response.body || !response.body.completed){
					// Reload test every 8s if not completed
					setInterval(
						() => this.reloadTests(),
						2000
					);
				}
				this.checkBlockedNextTest();
			})
			.catch((error) => {
				console.error(error);
				window.location.href = "https://www.mailreach.co/upgrade/";
			});
    }
	}

	reloadTests(){
    this.setState({reloadTests: true});
    TestService.get(this.props.match.params.test_id).then((response) => {
			// Redirect to upgrade page if test is blocked
			if(response.body.blocked){
				window.location.href = "https://www.mailreach.co/upgrade/";
				return;
			}
      this.setState({currentTest: response.body, reloadTests: false})
      this.filterAccounts(response.body.test_type);
			this.checkBlockedNextTest();
			this.loadPopup();
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
	}

	checkBlockedNextTest(){
		TestService.checkBlocked(this.props.match.params.test_id).then((response) => {
			this.setState({blockedNextTest: response.body.blocked})
		})
	}

	//
	calculeNumberSetupAlert(currentTest){
		let errors = 0;
		// Calculate Setup error
		if(currentTest.domain){
			if(!currentTest.domain.spf_valid){
				errors = errors + 1;
			}
			if(!currentTest.domain.dkim_valid){
				errors = errors + 1;
			}

			if(currentTest.domain.creation_date){
				if(CreatedInDayAgo(currentTest.domain.creation_date) < 32){
					errors = errors + 1;
				}
			}

			DomainBlacklistService.get(currentTest.public_id).then((response) => {
				let blacklistsFounds = response.body.filter((item) => item.listed).length;
				if(blacklistsFounds > 0){
					errors = errors + 1;
				}
				this.setState({error: {setup: errors}})
			});
		}
	}

	getScoreClass(score){
		if(score < 5){
			return 'bad';
		} else if (score < 7.5) {
			return 'average'
		}else {
			return 'good'
		}
	}

	filterAccounts(type){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let currentTest = this.state.currentTest;
		let spamLogs = currentTest.results;
		if(type == 'btob'){
			spamLogs.map(spamLog => spamLog.hidden = freeMails.includes(spamLog.email.split('@')[1]));
		}
		if(type == 'btoc'){
			spamLogs.map(spamLog => spamLog.hidden = !freeMails.includes(spamLog.email.split('@')[1]));
		}
		if(type == 'all'){
			spamLogs.map(spamLog => spamLog.hidden = false);
		}

		// Re-filter only btob and btoc accounts
		if(['btob', 'btoc'].includes(type)){
			spamLogs = spamLogs.sort(function(a, b){
				if(a.provider < b.provider) { return -1; }
				if(a.provider > b.provider) { return 1; }
				return 0;
			});
		};

		currentTest.results = spamLogs;
		this.setState({currentTest, spamTestInboxFilter: type});
	}

	setTestType(type){
		// Set test type
		TestService.setType(this.props.match.params.test_id, type).then((response) => {
			// Reload test and show restults
			this.reloadTests();
			//toast.success('Test type saved !');
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	getInboxTypelabel(email){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let domain = email.split('@')[1];
		if(freeMails.includes(domain)){
			return '<span class="inbox-type-label perso">Personal</span>'
		}else{
			return '<span class="inbox-type-label pro">Professional</span>'
		}
	}

	getInboxType(email){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let domain = email.split('@')[1];
		if(freeMails.includes(domain)){
			return 'perso'
		}else{
			return 'pro'
		}
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.success('Share link successfully copied 🙌');
	}

	loadPopup(){
		if(!localStorage.getItem('hide_popup_wrm')){
			if((this.state.currentTest.btob_score < 8) && this.state.currentTest.test_type && ['all', 'btob'].includes(this.state.currentTest.test_type) && this.state.currentTest.spam){
				if(this.state.currentTest.domain){
					if(this.state.currentTest.domain.spf_valid && this.state.currentTest.domain.dkim_valid && ['gmail.com', 'google.com', 'outlook.com', 'outlook.fr', 'hotmail.com'].includes(this.state.currentTest.sending_provider)){
						if(this.state.showPopup == false){
							this.setState({showPopup: true});
							window.gtag("event", "spamtest", {
											event_category: "spamtest",
											event_label: "warmer_pop_up"
									});
						}
					}
				}
			}
		}
	}

	closePopup(){
		this.setState({showPopup: false});
		localStorage.setItem('hide_popup_wrm', true);
	}

	openHeadersModal(log){
		if(Array.isArray(log.raw_headers)){
			this.setState({openShowHeadersModal: true, headersToShow: log.raw_headers});
		}
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
					<ToastContainer />
				</div>
			)
		}

		if(this.state.currentTest){
			if(!this.state.currentTest.first_message_content){
				return(
					<div className="app container home">
						<div className="card upgrade-spamchecker waiting">
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/home${this.props.location.search}`} className="close" activeClassName="active">
								<i class="fas fa-xmark"></i>
							</NavLink>
							<div className="col-md-9">
								<h2>MailReach is waiting to receive your email</h2>
								<p>
								It usually takes between 20 and 60 seconds to load your result. If nothing shows up after 60 seconds :<br /><br />
								1. Are you sure you added the custom code in your email?<br />
								2. Are you sure your email has been sent?<br />
								</p>
							</div>

							<div className="col-md-3 text-center">
								<div className="loader">
									<Loader
									 type="Oval"
									 color="#b7b7b7"
									 height="100"
									 width="100"
									/>
								</div>
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				)
			}
		}

		return (
      <>
      <div className="sub-nav">
        <div className="ct">
          <div className="account-name pull-left">
					  <div className="ar-nav">

						</div>
          </div>
          <ul className="pull-right">
            <li>
              <a onClick={() => this.setState({currentPage: 'test'})} className={this.state.currentPage == 'test' ? 'active' : ''}><i class="fas fa-envelope-open-text"></i> Deliverability</a>
              <a onClick={() => this.setState({currentPage: 'content'})} className={this.state.currentPage == 'content' ? 'active' : ''}>
								<i class="fas fa-spell-check"></i>
								Content Analysis
								{(this.state.currentTest.analysis_results && this.state.currentTest.analysis_results.error_count > 0) &&
									<span className="nav-error-count">
										{this.state.currentTest.analysis_results.error_count}
									</span>
								}
							</a>
							<a onClick={() => this.setState({currentPage: 'technical'})} className={ 'no-padding-right ' + (this.state.currentPage == 'technical' ? 'active' : '')}>
								<i class="fas fa-stethoscope"></i>
								Setup Analysis
								{this.state.error &&
									<>
										{this.state.error.setup > 0 &&
											<span className="nav-error-count">
												{this.state.error.setup}
											</span>
										}
									</>
								}
							</a>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>

			<div className="app home container spamtest-results spam-results-modal template-home">

			{(!this.state.currentTest.test_type) &&
				<div className="requested-type-selector">
				  <p>To see your result, select the type of inboxes you target :</p>

					<button className="selector blue" onClick={() => this.setTestType('btob')}>👔 Professional inboxes</button>
					<button className="selector yellow" onClick={() => this.setTestType('btoc')}>🙎 Personal inboxes</button>
					<button className="selector red" onClick={() => this.setTestType('all')}>‍🌎 All types of inboxes</button>
				<div className="clearfix"></div>

					<div>
						<p>
						<Tooltip title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa.
						Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam.
								Conclusion : your true deliverability has to be checked with the inbox type used by your real recipients." placement="bottom">
							<div>
								Important : spam filters work differently between professional and personal inboxes. To avoid biased results, choose the type of inboxes used by the recipients of your real campaigns. You can change that later.
							</div>
						</Tooltip>

							<div className="help-bubble-dropdown">
								<Tooltip title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa.
								Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam.
								Conclusion : your true deliverability has to be checked with the inbox type used by your real recipients." placement="bottom">
									<div>
										<i class="fas fa-info-circle"></i>
									</div>
								</Tooltip>
							</div>
						</p>
					</div>
				</div>
			}

				<div className="row accounts templates col-md-12 container">
					{this.state.currentPage == 'test' &&
						<h2>
							Result
						</h2>
					}

					{(this.state.currentPage == 'test' && this.state.currentTest.test_type) &&
						<Dropdown className="dropdown-date-filter last-days-filter pull-left">
							<Dropdown.Toggle className={"btn text-type-badge " + this.state.currentTest.test_type} id="dropdown-basic">
		            <div className="pull-left help-bubble-dropdown">
									<Tooltip title="It is not relevant to check your deliverability to personal addresses if you are targeting professionals and vice versa. Google and Microsoft understand your emails and may consider it suspicious if you are talking business to a personal inbox, which is not supposed to happen. The result? They put you in spam. Conclusion: your true deliverability should be checked with the type of inbox used by your real recipients." placement="bottom">
										<div>
											<i class="fas fa-info-circle"></i>
										</div>
									</Tooltip>
								</div>

								{getTypeIco[this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : 'all']}

								<i class="fas fa-angle-down" aria-hidden="true"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu className="test-type-dropdown">
								<Dropdown.Item onClick={() => this.setTestType('all')}>🌎‍ All types of inboxes</Dropdown.Item>
								<Dropdown.Item onClick={() => this.setTestType('btob')}>👔 Professional inboxes</Dropdown.Item>
								<Dropdown.Item onClick={() => this.setTestType('btoc')}>🙎 Personal inboxes</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					}

					{this.state.currentPage == 'content' &&
						<h2>Content Analysis</h2>
					}

					{this.state.currentPage == 'technical' &&
						<h2>Setup Analysis</h2>
					}


					{!this.state.currentTest.completed &&
						<Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
						  <div className="score running pulse">
							  Searching...
						  </div>
						</Tooltip>
					}


					 <div className="pull-right hide-responsive">
						 <a href={this.state.blockedNextTest ? ('https://www.mailreach.co/upgrade/' + this.props.location.search) : ('https://www.mailreach.co/email-spam-test/' + this.props.location.search)} target="_blank" className="btn btn-header btn-secondary pull-right blue-btn">Run a new test <i class="fas fa-arrow-right"></i></a>
						 <button type="submit" disabled={this.state.loading} className="btn btn-header btn-secondary pull-right" onClick={() => this.setState({openShowTemplateModal: true})}>See my email</button>
					 </div>

					<div className="clearfix"></div>
          <div className={'txt text-center ' + (this.state.spamTestInboxFilter ? '' : 'blured')}>
					{this.state.currentPage == 'test' &&
						<>
						{this.state.currentTest.completed &&
							<>
								<div className="col-md-3 no-padding-left text-left">
									<div className="card">
									  <div className="title">
										Score

										<div className="help-bubble pull-right">
											<Tooltip title="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10." placement="bottom">
												<div>
													<i class="fas fa-info-circle"></i>
												</div>
											</Tooltip>
										</div>
										</div>
										<div className="text-center">
											<Tooltip title="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10." placement="bottom">
									    	<div className={'inbox-rate-nb score ' + this.getScoreClass(this.state.currentTest.final_score)}>{this.state.currentTest.final_score}/10</div>
											</Tooltip>
										</div>
									</div>
								</div>
								<div className="col-md-9 no-padding-right text-left">
									<div className="card upgrade-spamchecker">
										{(adsRd == 1) &&
										  <div className="insight-content">
												<h2 className="pull-left">Unlock the full potential of the spam checker</h2>
												<p className="pull-left">Run more tests, see your progress history, optimize your deliverability, improve your open rates and make more sales.</p>
												<div className="col-md-12">
													<a className="btn btn-primary green-bck" href={'https://www.mailreach.co/unlock-spam-test-potential/' + (this.props.location.search ? ( this.props.location.search + '&utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=deliverability-tab') : '?utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=deliverability-tab')} target="_blank">
														Upgrade
													</a>
												</div>
												<div className="clearfix"></div>
											</div>
										}

										{(adsRd == 2) &&
											<div className="insight-content">
												<h2 className="pull-left">Sending cold emails? Use the best email warmer.</h2>
												<p className="pull-left">MailReach's email warmer generates engagement to your emails to raise your reputation and deliverability. It teaches Google & Microsoft to send your emails to the inbox.</p>
												<div className="col-md-12">
													<a className="btn btn-primary green-bck" href={'https://www.mailreach.co/email-warmup/' + (this.props.location.search ? ( this.props.location.search + '&utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=deliverability-tab') : '?utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=deliverability-tab')} target="_blank">
														Boost my deliverability
													</a>
												</div>
												<div className="clearfix"></div>
											</div>
										}

									</div>
								</div>
							</>
						}
						<div className="clearfix"></div>
						<h2 className="second-title">
							Where it landed
						</h2>
						{this.state.reloadTests &&
							<div className="pull-left spamtests-loading top-margin">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="25"
								 width="25"
								/>
							</div>
						}
						<div className="share-link hide-responsive" onClick={() => this.copyText(window.location.href.split('?')[0])}>
							<div className="code">
								{window.location.href.split('?')[0].slice(0, 50)}
							</div>
							<div className="copy">
								<i className="far fa-copy" aria-hidden="true"></i>
							</div>
						</div>
						<div className="share-txt hide-responsive">
							Share this report
						</div>
						<div className="clearfix"></div>
						</>
					}
					  {!['technical', 'content'].includes(this.state.currentPage) &&
	            <div className="card rp-right-margin">
	              {this.state.currentPage == 'test' &&
	                <>
	                  {(this.state.currentTest.results.length == 0 && !this.state.loading) &&
	                    <div className="empty text-center">
	                      <img src="/images/instat_analysis.svg" />
	                      <p>No results at the moment.</p>
	                    </div>
	                  }
	                  <table class="table table-results">
	                    <tbody>
	                    {this.state.currentTest.results.map((log, index) => {
												if(log.hidden){
													return;
												}
	                      return (
	                        <tr>
	                          <td>
														  <div>
																{log.provider == 'custom' &&
																	 <img className="avatar pull-left" src={'/images/custom.svg'} />
																}
																{log.provider != 'custom' &&
																	 <img className="avatar pull-left" src={'/images/logo_' + log.provider + '.png'} />
																}
															</div>
	                            <span className="hide-responsive pointer" onClick={() => this.openHeadersModal(log)}>{log.email}</span><br />
	                            <span className="account-desc hide-responsive">{log.desc}</span>
	                          </td>
	                          <td className="inbox-type">
	                            <span className={"providerbadge hide-responsive " + log.provider}>
															  {log.provider == 'gmail' &&
																  <>Google</>
																}
																{log.provider == 'outlook' &&
																  <>Microsoft</>
																}
																{!['gmail','outlook'].includes(log.provider) &&
																	<>{log.provider}</>
																}
	                            </span>

															<div dangerouslySetInnerHTML={{__html: this.getInboxTypelabel(log.email)}} />
	                          </td>
	                          <td className="hour hide-responsive">
	                          <Moment fromNow>{this.state.currentTest.created_at}</Moment>
	                          </td>
	                          <td>
	                            {(!log.received_in && !this.state.currentTest.completed) &&
																<Tooltip title="Pending means MailReach is still looking for your email in this mailbox. It will be updated soon. If nothing is found after 6 minutes, it's considered as missing and your score is displayed." placement="bottom">
	                              	<span className={"badge pending"}>Pending</span>
																</Tooltip>
	                            }
	                            {(!log.received_in && this.state.currentTest.completed) &&
																<Tooltip title="Missing means MailReach doesn't find your email in this mailbox. It can mean your email has not landed yet OR it can mean the mailbox / provider didn't let you enter. In the latter, that's worst than spam." placement="bottom">
																	<span className={"badge missing-badge-color"}>Missing</span>
																</Tooltip>
	                            }
	                            {log.received_in &&
																<>
																	{log.received_in == 'INBOX' &&
																		<Tooltip title="Well done 🙌" placement="bottom">
																			<span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
																		</Tooltip>
																	}
																	{(log.received_in == 'SPAM' && this.getInboxType(log.email) == 'perso')  &&
																		<Tooltip title="Not good BUT the question is : do you really target personal inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes." placement="bottom">
																			<span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
																		</Tooltip>
																	}
																	{(log.received_in == 'SPAM' && this.getInboxType(log.email) == 'pro')  &&
																		<Tooltip title="Not good BUT the question is : do you really target professional inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes." placement="bottom">
																			<span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
																		</Tooltip>
																	}
																	{!['INBOX', 'SPAM', 'MISSING'].includes(log.received_in) &&
																		<Tooltip title="Better than spam, but not the best 😐" placement="bottom">
																			<span className="badge categorie">{log.received_in.toLowerCase()}</span>
																		</Tooltip>
																	}
																</>
	                            }
	                          </td>
	                        </tr>
	                      )})}
	                    </tbody>
	                  </table>
	                </>
	              }
	            </div>
						}
						{this.state.currentPage == 'content' &&
							<div className="checkups">
							<div className="col-md-12 no-padding-right no-padding-left text-left">
								<div className="card upgrade-spamchecker">
								<div className="insight-content">
									<h2 className="pull-left">Sending cold emails? Make sure to land in inbox and sell more 🔥</h2>
									<p className="pull-left">MailReach's email warmer generates engagement to your emails to raise your reputation and deliverability. It teaches Google & Microsoft to send your emails to the inbox. Get higher open, click, reply rates and make more sales.</p>
									<div className="col-md-12">
										<a className="btn btn-primary green-bck" href={'https://www.mailreach.co/email-warmup/' + (this.props.location.search ? ( this.props.location.search + '&utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=content-tab') : '?utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=content-tab')} target="_blank">
											Boost my deliverability
										</a>
									</div>
									<div className="clearfix"></div>
								</div>

								</div>
							</div>

								<WordsAnalyzer title={false} currentTest={this.state.currentTest} contentSpamwordsResponse={this.state.currentTest.analysis_results} />
								<div className="clearfix"></div>
							</div>
						}
						{this.state.currentPage == 'technical' &&
							<div className="checkups">
							<div className="col-md-12 no-padding-right no-padding-left text-left">
								<div className="card upgrade-spamchecker">
								<div className="insight-content">
									<h2 className="pull-left">Sending cold emails? Maximize your inbox rate and get more sales 🔥</h2>
									<p className="pull-left">MailReach's email warmer generates engagement to your emails to raise your reputation and deliverability. It teaches Google & Microsoft to send your emails to the inbox. Get higher open, click, reply rates and make more sales.</p>
									<div className="col-md-12">
										<a className="btn btn-primary green-bck" href={'https://www.mailreach.co/email-warmup/' + (this.props.location.search ? ( this.props.location.search + '&utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=setup-tab') : '?utm_source=free_spamchecker&utm_medium=warmer_cta&utm_campaign=setup-tab')} target="_blank">
											Boost my deliverability
										</a>
									</div>
									<div className="clearfix"></div>
								</div>

								</div>
							</div>

								<CheckupDomain domain={this.state.currentTest.domain} currentTest={this.state.currentTest} spamTest={true} />
								<div className="clearfix"></div>
							</div>
						}

          </div>
        </div>
      </div>
      {this.state.currentTest.first_message_content &&
  			<Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
  				<Modal.Body>
  					<>
  						<h1 className="text-center">{this.state.currentTest.first_message_content.subject}</h1>
  						<div className='from'>
  							<b>{this.state.currentTest.first_message_content.from_name}</b> {'<' + this.state.currentTest.first_message_content.from_email + '>'}
								<ul className='format-selector pull-right'>
									<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
									<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
								</ul>
  						</div>
  						<div className="txt">
  						<div
  dangerouslySetInnerHTML={{__html: this.state.currentTest.first_message_content[this.state.messageContentPreview]}}
  />
  						</div>
  						<div className="footer text-center">
  							<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
  						</div>
  					</>
  				</Modal.Body>
  			</Modal>
      }

				<Modal className="spam-results-modal message-design" show={this.state.openShowHeadersModal} onHide={() => this.setState({openShowHeadersModal: false})}>
					<Modal.Body>
						<>
							<h1 className="text-center">Message Raw Headers</h1>
							<div className='headers'>
								<table>
									<tbody>
										{this.state.headersToShow.map((header, index) => {
											return (
												<tr>
													<td>{header.name} :</td>
													<td className="break-word">{header.unparsed_value}</td>
												</tr>
											)})}

									</tbody>
								</table>
							</div>
							<div className="footer text-center">
								<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowHeadersModal: false})}>Close</button>
							</div>
						</>
					</Modal.Body>
				</Modal>

			<ToastContainer />
			<footer className="text-center">
				<a href="https://www.mailreach.co/?utm_source=free_spamchecker&utm_medium=footer-links&utm_campaign=mailreach-cta" target="_blank">Maximize your cold email deliverability with MailReach</a>
				<a href="https://www.mailreach.co/blog/why-are-my-emails-going-to-spam?utm_source=free_spamchecker&utm_medium=footer-links&utm_campaign=why-emails-going-to-spam" target="_blank">Why are my emails going to spam?</a>
				<a href="https://www.mailreach.co/blog/how-to-prevent-emails-from-going-to-spam?utm_source=free_spamchecker&utm_medium=footer-links&utm_campaign=deliverability-guide" target="_blank">The Ultimate Deliverability Guide</a>
			</footer>

			{this.state.showPopup &&
				<>
					<div className="overlay"></div>
					<div className="pop-warmer text-center">
						<i onClick={this.closePopup} class="fas fa-times"></i>
						<div className="text-center">
							<img src="/images/popup_warmer.svg" />
						</div>
						<h1>We're 80% sure our email warmer can help you.</h1>
						<p>As your content and sending setup look OK, you may land in spam because of a damaged sender reputation.</p>

						<div className="text-center">
							<a className="btn btn-primary green-bck" href="https://www.mailreach.co/?utm_source=free_spamchecker&utm_medium=smart-popup-warmer">Raise my reputation</a>
						</div>
					</div>
				</>
			}
      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default AppTests;
